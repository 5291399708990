<template>
  <div class="nonactivateduser" style="margin-bottom: 100px;">
    <div>

      <div class="hiddendiv20"></div>

      <div class="hiddendiv20"></div>

      <div class="hiddendiv"></div>

      <img height="50px;" src="https://gcdn.bionluk.com/site/icon/aws/icon_ok.svg">

      <div class="hiddendiv"></div>

      <h2>Son Bir Adım Kaldı</h2>

      <div class="hiddendiv"></div>

      <div class="subheading1-text">Hesabının aktif hale gelmesi için <strong>{{user.email}} </strong> adresine bir e-posta gönderdik. <br> <br>Lütfen e-posta hesabını kontrol et.</div>
      <div class="hiddendiv"></div>



      <a v-on:click="activateResendingActivation()" v-if="!showResending" style="cursor: pointer; color: #fd4056!important;font-weight: 500!important;">Tekrar Gönder</a>



      <form v-if="showResending" @submit.prevent="resendActivation(email)">
        <input type="text" placeholder="E-mail" v-model="email" style="width:270px; font-size: 16px;">
        <div class="hiddend10"></div>
        <button style="margin-top: 20px;" :disabled="checkDisable" type="submit" class="super-button">Aktivasyonu Tekrar Gönder</button>
      </form>


      <div class="hiddendiv10"></div>
      <div class="hiddendiv"></div>

      <div style="color: #8b95a1; font-size: 14px; padding: 0 20px; font-weight: 400!important;" class="subheading2-text">Yardıma ihtiyacın olursa dilediğin zaman bize <router-link to="/tickets">yazabilirsin.</router-link></div>

      <div class="hiddendiv20"></div>




      <p style="font-weight: 400; font-size: 14px; color: #000000; text-decoration: underline; cursor: pointer;" @click="refreshPage()">Sayfayı Yenile</p>
      <div class="hiddendiv"></div><div class="hiddendiv"></div>
      <a style="font-weight: 400; font-size: 14px; color: #5e6b79; text-decoration: underline;" href="javascript:void(0)" @click="logout()">Çıkış Yap</a>
    </div>
  </div>
</template>

<script>

  //import LogRocket from 'logrocket';

  export default {
    name: "src-pages-body-auth-nonactivateduser-v1",
    data() {
      return {
        toggleDisable: false,
        email: "",
        showResending: null
      }
    },

    computed: {
      checkDisable() {
        return this.toggleDisable
      }
    },

    methods: {
      activateResendingActivation() {
        this.showResending = true;
      },

      logout() {
        this.api.user.logout(this.$Progress).then(({data}) => {
          let result = data;
          if(result.success) {
            //window.Intercom('shutdown');
            //LogRocket.identify();

            amplitude.regenerateDeviceId();
            amplitude.setUserId(null);
            localStorage.removeItem("token");
            localStorage.removeItem("visitor_id");
            window.location.href = "/"
          } else {
            this.$toasted.global.errorToast({description: result.message});
          }
        }).catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      },

      refreshPage() {
        location.reload();
      },

      resendActivation(email) {
        this.toggleDisable = true;
        this.api.user.resendActivation(email, this.$Progress).then(({data}) => {
          this.toggleDisable = false;
          let result = data;
          if(result.success) {
            this.$toasted.global.infoToast({description: result.message});
            this.$store.commit(this.types.SET_USER_CHANGES, {key: "email", value: email});
            this.showResending = false;
          } else {
            this.$toasted.global.errorToast({description: result.message});
          }
        }).catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      }
    },

    created(){
      this.email = this.user.email;
    }
  }
</script>
<style scoped>
  .nonactivateduser{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    align-items: center;


    text-align: center;
  }







</style>
